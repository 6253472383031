<template>
  <v-container>
    <!-- SEO https://habr.com/ru/post/539042/ -->
    <v-row class="text-center" style="height: 100px">
      <v-col cols="6">
        <v-row height="275">
          <v-col cols="2" class="d-flex flex-column align-left">
            <v-img alt="MK Plus Mobile App Development & Test Automation" class="shrink mt-5" contain
              :src="require('@/assets/logo.png')" transition="scale-transition" width="75" />
          </v-col>
          <v-col cols="10">
            <v-row class="justify-center">
              <div class="mt-5 text-h6">MK PLUS &#8226;
                <a style="text-decoration: none; color: #7C4DFF;" href="https://www.linkedin.com/in/maxkrut/"
                  target="_blank">LINKEDIN
                </a>
              </div>
            </v-row>
            <v-row class="justify-center">
              <div class="text-overline">1-250-3208951 &#8226; Ottawa, ON, Canada</div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6 d-flex justify-end">
        <v-row>
          <v-col cols="9">
            <v-row class="justify-center">
              <div class="mt-5 text-h6">DATA WAREHOUSE QUALITY AUTOMATION</div>
            </v-row>
            <v-row class="justify-center">
              <div class="text-overline">UX & QA Audit &#8226; APP DEV & TEST AUTOMATION</div>
            </v-row>
          </v-col>
          <v-col cols="3" class="d-flex flex-column">
            <v-btn class="mt-3" outlined @click.stop="contactModalShown = true">Contact</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col class="deep-purple accent-2 d-flex flex-column align-center justify-center" sm="12" md="6">
        <a style="text-decoration: none; color: #7C4DFF;" href="https://github.com/MaksymKrut/appium2-greenfield"
          target="_blank">
          <v-img alt="Appium Project Maksym Krutskykh GitHub" class="shrink mr-2" contain
            :src="require('@/assets/data.png')" transition="scale-transition" width="260" />
        </a>
      </v-col>
      <v-col sm="12" md="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h2 font-weight-light my-10">
          <a style="text-decoration: none; color: #7C4DFF;" href="https://github.com/MaksymKrut/appium2-greenfield"
            target="_blank">
            <h6>Data Quality Automation</h6>
          </a></span>
        <v-divider class="deep-purple accent-2 mb-5" width="420"></v-divider>
        <article>
          <h3 hidden>Data Warehouse ETL Testing</h3>
          <p>
            <span class="text-h6 font-weight-light">
              Data Warehouse ETL Testing and Data Quality Management ensure the accuracy, consistency, and reliability
              of data within a data warehouse. Automating the process with Python and the Great Expectations library
              enhances ETL testing by enabling scalable data validation. It facilitates the definition, testing, and
              continuous monitoring of data expectations, ensuring adherence to quality standards. Additionally,
              automation helps detect anomalies early, improving efficiency, maintaining data integrity, and
              strengthening trust in business intelligence, ultimately preventing errors that could impact critical
              business decisions.
            </span>
          </p>
        </article>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col sm="12" md="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h2 font-weight-light my-10">
          <a style="text-decoration: none; color: #7C4DFF;" href="https://www.linkedin.com/in/maxkrut/" target="_blank">
            <h3>UX & QA Audit</h3>
          </a></span>
        <v-divider class="deep-purple accent-2 mb-5" width="450"></v-divider>
        <article>
          <h3 hidden>UX & QA Audit</h3>
          <p>
            <span class="text-h6 font-weight-light">
              User Experience and Quality Assurance are important parts of
              software development lifecycle. Independent professional opinion
              as a third party between buyer and provider is the best way to avoid
              inevitable <b style="text-decoration: none; color: red;">Conflict of Interest</b>
              of the provider between in-time feature delivery and its quality.
            </span>
          </p>
        </article>
      </v-col>
      <v-col class="deep-purple accent-2 d-flex flex-column align-center justify-center" sm="12" md="6">
        <a style="text-decoration: none; color: #7C4DFF;" href="https://www.linkedin.com/in/maxkrut/" target="_blank">
          <v-img alt="UX and QA Automation QA Audit Maksym Krutskykh" class="shrink mr-2" contain
            :src="require('@/assets/audit.png')" transition="scale-transition" width="260" />
        </a>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col class="deep-purple accent-2 d-flex flex-column align-center justify-center" sm="12" md="6">
        <a style="text-decoration: none; color: #7C4DFF;" href="https://github.com/MaksymKrut/appium2-greenfield"
          target="_blank">
          <v-img alt="Appium Project Maksym Krutskykh GitHub" class="shrink mr-2" contain
            :src="require('@/assets/appium.png')" transition="scale-transition" width="260" />
        </a>
      </v-col>
      <v-col sm="12" md="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h2 font-weight-light my-10">
          <a style="text-decoration: none; color: #7C4DFF;" href="https://github.com/MaksymKrut/appium2-greenfield"
            target="_blank">
            <h3>Mobile App Test Automation</h3>
          </a></span>
        <v-divider class="deep-purple accent-2 mb-5" width="420"></v-divider>
        <article>
          <h3 hidden>Mobile App Test Automation</h3>
          <p>
            <span class="text-h6 font-weight-light">
              With modern test tools like Appium, automation possible
              for both main mobile platforms, iOS and Android. Written ones
              tests are running 24/7 catching million $ code mistakes!
            </span>
          </p>
        </article>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col sm="12" md="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h2 font-weight-light my-10">
          <a style="text-decoration: none; color: #7C4DFF;" href="https://apkpure.com/developer/Maksym%20Krutskykh"
            target="_blank">
            <h3>Android App Development</h3>
          </a></span>
        <v-divider class="deep-purple accent-2 mb-5" width="450"></v-divider>
        <article>
          <h3 hidden>Android App Development</h3>
          <p>
            <span class="text-h6 font-weight-light">
              Global representation for business. Address billions of
              devices. Development can be done together with iPhone app
              with a hybrid Flutter approach, but can be platform specific.
            </span>
          </p>
        </article>
      </v-col>
      <v-col class="deep-purple accent-2 d-flex flex-column align-center justify-center" sm="12" md="6">
        <a style="text-decoration: none; color: #7C4DFF;" href="https://apkpure.com/developer/Maksym%20Krutskykh"
          target="_blank">
          <v-img alt="Play Store Android App Development Maksym Krutskykh" class="shrink mr-2" contain
            :src="require('@/assets/play_store.png')" transition="scale-transition" width="260" />
        </a>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col class="deep-purple accent-2 d-flex flex-column align-center justify-center" sm="12" md="6">
        <a style="text-decoration: none; color: #7C4DFF;"
          href="https://apps.apple.com/us/developer/maksym-krutskykh/id1618027639" target="_blank">
          <v-img alt="App Store App Development Maksym Krutskykh" class="shrink mr-2" contain
            :src="require('@/assets/app_store.png')" transition="scale-transition" width="260" />
        </a>
      </v-col>
      <v-col sm="12" md="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h2 font-weight-light my-10">
          <a style="text-decoration: none; color: #7C4DFF;"
            href="https://apps.apple.com/us/developer/maksym-krutskykh/id1618027639" target="_blank">
            <h3>iPhone App Development</h3>
          </a></span>
        <v-divider class="deep-purple accent-2 mb-5" width="420"></v-divider>
        <article>
          <h3 hidden>iPhone App Development</h3>
          <p>
            <span class="text-h6 font-weight-light">
              Apps for iPhone, iPad, MacOS. Any scale and
              functionality. May include maps, chats, camera QR
              scanning and more. Strong market share in the US, Canada.
            </span>
          </p>
        </article>
      </v-col>
    </v-row>



    <v-row class="text-center" style="height: 24px">
    </v-row>

    <Contact :contactModalShown.sync="contactModalShown" />
    <Invest :investModalShown.sync="investModalShown" />
  </v-container>
</template>

<script>
import Contact from '@/modals/Contact.vue';
import Invest from '@/modals/Invest.vue';

export default {
  name: 'Home',
  components: {
    Contact,
    Invest,
  },
  data() {
    return {
      contactModalShown: false,
      investModalShown: false,
    };
  },
};
</script>
